<template>
  <main id="curriculum">
    <Heading1 class="hidden">{{ $t("curriculum.title")}}</Heading1>
    <Heading2>{{ $t("curriculum.aboutTitle")}}</Heading2>
    <div class="lg:text-xl md:text-xl mb-8 print:text-sm print:mb-6" v-html="localeContent.description">
    </div>
    <div class="print:flex">
      <section id="curriculum-work" class="print:w-2/3 print:pr-8">
        <Heading2>{{ $t("curriculum.workTitle")}}</Heading2>
        <Divider/>
        <ul>
          <li
            v-for="item in localeContent.work"
            :key="item.organization"
            class="lg:grid pb-2 mb-2 lg:pb-6 lg:mb-6 border-b border-gray-200 dark:border-gray-700 last:border-none last:mb-8 print:border-none"
          >
            <div class="lg:pr-4">
              <h3 class="font-bold uppercase text-lg print:text-sm">
                {{ item.organization }}
              </h3>
              <p class="font-light text-gray-600 mb-4 print:mb-0 print:text-sm">
                {{ item.from }} - {{ item.to ? item.to : $t("curriculum.presentDay") }}
              </p>
            </div>
            <div>
              <p class="print:text-sm">{{ item.description }}</p>
            </div>
          </li>
        </ul>
      </section>
      <div class="lg:grid grid-cols-2 gap-8 print:w-1/3">
        <section id="curriculum-education">
          <Heading2>{{ $t("curriculum.educationTitle")}}</Heading2>
          <Divider/>
          <ul>
            <li
              v-for="item in localeContent.education"
              :key="item.organization"
              class="pb-2 mb-2 lg:pb-6 lg:mb-6 border-b border-gray-200 dark:border-gray-700 last:border-none last:mb-8 print:border-none"
            >
              <h3 class="font-bold uppercase text-lg print:text-sm">
                {{ item.organization }}
              </h3>
              <p class="font-light text-gray-600 mb-4 print:mb-0 print:text-sm">
                {{ item.from }} - {{ item.to ? item.to : $t("curriculum.presentDay") }}
              </p>
              <p class="print:text-sm">{{ item.description }}</p>
            </li>
          </ul>
        </section>
        <section id="curriculum-skills">
          <Heading2>{{ $t("curriculum.skillsTitle")}}</Heading2>
          <Divider/>
          <ul class="mb-4 print:text-sm">
            <li class="mb-4" v-for="skill in localeContent.skills" :key="skill">
              <h3 class="font-bold text-lg uppercase print:text-sm">{{ skill.title }}</h3>
              <p>{{ skill.content }}</p>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import Heading1 from '../components/ui/Heading1.vue'
import Heading2 from '../components/ui/Heading2.vue'
import Divider from '../components/ui/Divider.vue'
export default {
  components: {
    Heading1,
    Heading2,
    Divider
  },
  computed: {
    curriculum() {
      return this.$store.getters["curriculum/curriculum"];
    },
    localeContent() {
      return this.curriculum.content[this.$i18n.locale]
    }
  },
  created() {
    document.title = this.$t("nav.curriculum") + " | Piero del Valle";
  },
};
</script>

<style>
html.dark .text-gray-600 {
  @apply text-gray-300;
}
#curriculum-work li {
  grid-template-columns: 25% 1fr;
}
</style>